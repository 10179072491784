<template>
  <div>
    <div :class="$style.home">
      <a-row :class="$style.head">
        <a-col span="12" :class="$style.title"> 分组列表 </a-col>
        <a-col span="12" class="text-right">
          <BjButton v-permission="'content.group-create'" type="primary" @click="onAdd()">
            <i class="ri-add-line left" /> 添加分组
          </BjButton>
        </a-col>
      </a-row>
      <a-table
        :loading="loading"
        :columns="columns"
        :components="components"
        :data-source="data"
        :pagination="false"
        row-key="id"
      >
        <template #mover="item">
          <img
            v-if="item.is_ungrouped === 0"
            :class="$style.mover"
            class="mover"
            :src="oss + '/manage/icon/default/sort.png'"
          />
        </template>
        <template #img="item"> <img :class="$style.icon" :src="item.icon" /> </template>
        <template #action="item">
          <div v-if="!item.is_ungrouped">
            <bj-link v-permission="'content.group-edit'" type="primary" @click="onEdit(item)"> 编辑 </bj-link>
            <a-popconfirm
              placement="topLeft"
              title="确定要删除资讯分组吗？删除之后该分组下的资讯将放至默认分组中。"
              @confirm="onDelete(item)"
            >
              <bj-link v-permission="'content.group-delete'" type="danger"> 删除 </bj-link>
            </a-popconfirm>
          </div>
          <span v-else>-</span>
        </template>
      </a-table>
    </div>
    <bj-modal
      :class="$style.modal"
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <BjForm>
        <BjInput
          v-model="edit.name"
          label="分组名称"
          v-bind="layout"
          label-align="left"
          rules="required"
          allow-clear
          placeholder="请输入分组名称"
        />
        <a-row class="mb-20">
          <a-col span="6" />
          <a-col span="18" :class="$style.tip">
            对文化内容进行分组，设置分组名称，便于更好运营和管理；也可以从下方系统提供的分组中选取。
          </a-col>
        </a-row>
        <BjValidationItem rules="required" label="分组图标" label-align="left" v-bind="layout">
          <!-- <BjButton class="btn-default" @click="choose()"><i class="ri-upload-line left" /> 选择文件 </BjButton>
          <div v-if="edit.icon" :class="$style.file">
            <img :src="edit.icon" />
          </div> -->
          <selectMaterial :data="edit.icon" :allow="['image']" @change="swiperChange" />
        </BjValidationItem>
        <a-row>
          <a-col span="6" />
          <a-col span="18" :class="$style.tip"> 图标建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。 </a-col>
        </a-row>
        <div v-if="defaultData.length" :class="$style.line" />
        <p v-if="defaultData.length" :class="$style.stitle">从系统提供的分组中选取</p>
        <div v-for="item in defaultData" :key="item.id" :class="$style.box" @click="addDefault(item)">
          <div :class="$style.tag" class="tag">{{ item.name }}</div>
        </div>
      </BjForm>
    </bj-modal>
    <chooseMaterial :visible.sync="chooseVisible" :allow="['image']" @getChoose="getChoose" />
  </div>
</template>

<script>
import BjDrag from '@/components/BjDrag'
import chooseMaterial from '@/components/chooseMaterial'
import selectMaterial from '@/components/selectMaterial'
import { contentGroupService } from '@/service'

const service = new contentGroupService()

export default {
  name: 'Home',
  components: {
    chooseMaterial,
    selectMaterial,
  },
  provide() {
    return {
      data: this,
    }
  },
  data() {
    return {
      data: [],
      visible: false,
      title: '分组',
      id: null,
      loading: false,
      confirmLoading: false,
      edit: {
        name: null,
        icon: {},
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      defaultData: [],
      chooseVisible: false,
      components: {
        body: {
          wrapper: BjDrag,
        },
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 50,
          scopedSlots: {
            customRender: 'mover',
          },
        },
        { title: '分组名称', dataIndex: 'name', ellipsis: true },
        {
          title: '分组图标',
          scopedSlots: {
            customRender: 'img',
          },
        },
        { title: '包含内容', dataIndex: 'content_total' },
        { title: '添加时间', dataIndex: 'created_at', customRender: value => value || '-' },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getList()
    this.getDefault()
  },
  methods: {
    async getDefault() {
      const { data } = await service.defaultList()
      this.defaultData = data
    },
    getChoose(data) {
      this.edit.icon = data[0].path
    },
    addDefault(item) {
      this.edit.name = item.name
    },
    onEdit(item) {
      this.title = '修改分组'
      this.id = item.id
      this.visible = true
      this.edit.name = item.name
      this.edit.icon = { cover: item.icon, type: 'image', icon_id: null }
    },
    onAdd() {
      this.title = '添加分组'
      this.id = null
      this.visible = true
      this.edit = {
        name: null,
        icon: {},
      }
    },
    async handleOk() {
      if (this.id) {
        await service.edit({
          name: this.edit.name,
          icon: this.edit.icon.cover,
          id: this.id,
          icon_id: this.edit.icon.id,
        })
        this.$message.success('编辑成功')
      } else {
        await service.add({
          name: this.edit.name,
          icon: this.edit.icon.cover,
          icon_id: this.edit.icon.id,
        })
        this.$message.success('添加成功')
      }
      this.visible = false
      this.getList()
    },
    handleCancel() {
      this.visible = false
    },
    async onDelete(item) {
      try {
        await service.delete({ id: item.id })
        this.$message.success('删除成功')
      } catch (e) {
      } finally {
        this.getList()
      }
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.list()
        this.data = data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    // eslint-disable-next-line vue/no-unused-properties
    async save() {
      try {
        let ids = this.data.map(item => item.id)
        await service.sort({ ids: ids })
      } catch (e) {}
    },
    swiperChange(data) {
      this.edit.icon = data[0]
    },
  },
}
</script>

<style lang="less" module>
.home {
  min-height: calc(100vh - 152px);
  padding: 20px;
  background: #fff;

  .head {
    margin-bottom: 20px;

    i {
      color: #fff;
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .title {
    color: #000;
    font-size: 16px;
    line-height: 32px;
  }

  .foot {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
    text-align: right;
  }
}

.tip {
  margin-top: 10px;
  color: #5c5c5c;
  font-size: 12px;
}

.stitle {
  margin-bottom: 14px;
  color: #5c5c5c;
  font-size: 12px;
}

.modal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .line {
    width: 100%;
    margin: 20px 0;
    border-bottom: 1px solid #eee;
  }

  .box {
    display: inline-block;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .tag {
    margin-bottom: 10px;
  }

  .tag:hover {
    color: #fff !important;
    background: @primary-color !important;
  }

  .file {
    padding: 8px 0;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
}

.icon {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.mover {
  width: 20px;
  height: 20px;
  display: none;
}

.mover:active {
  cursor: grabbing;
}
</style>
